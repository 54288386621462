<template>
      <div id="labelGenForm" class="form">
        <v-form v-if="loaded" ref="form" v-model="valid" lazy-validation>
          <v-text-field
              v-model="labelGen.product_id"
              label="Product"
              :required="false"
            />
            <v-text-field
              v-model="labelGen.brand"
              label="Brand"
              :required="false"
            />
            <v-text-field
              v-model="labelGen.inventory_id"
              label="Inventory Id"
              :required="false"
            />
            <v-text-field
              v-model="labelGen.prefix"
              label="Prefix"
              :required="true"
            />
            <v-text-field
              v-model="labelGen.code"
              label="Code"
              :required="true"
            />
            <v-text-field
              v-model="labelGen.weights"
              label="Weights"
              :required="false"
            />
            <v-text-field
              v-model="labelGen.stock_date"
              label="Stock Date"
              :required="true"
            />
            
        <div class="mt-4 d-flex justify-space-between">
        <v-btn @click="goBack">
          Back
        </v-btn>
        <v-btn @click="handleSubmit" color="primary">
          {{ id ? "Update" : "Save" }}
        </v-btn>
        <span></span>
      </div>
        </v-form>
      </div>
    </template>
    
<script>
  import { getLabelGen } from "@/services/labelGen";
  
  
  import { mapActions } from "vuex";

  

  export default {
    props: ["id"],
    data () {
      return {
        loaded: false,
        valid: false,
        labelGen: {
          relations: {
            
          },
          product_id: '',
brand: '',
inventory_id: '',
prefix: '',
code: '',
weights: '',
stock_date: '',

        }
      }
    },
    created() {
      this.setInstance();
    },
    methods: {
      ...mapActions("labelGen", ["createLabelGen", "updateLabelGen"]),
      ...mapActions("messages", [
        "addMessage",
        "addErrorMessage",
        "addSuccessMessage"
      ]),
      
      async handleSubmit() {
        if (this.id) {
          let labelGen = this.labelGen;
          delete labelGen.relations;

          this.updateLabelGen(labelGen)
          .then( (response) => {
            console.log(response);
            this.addSuccessMessage("LabelGen was updated successfully");
            this.goBack();
          }).catch( () => {
            this.addErrorMessage("Error updating LabelGen, please try again later");
          });
        } else {
          let labelGen = this.labelGen;
          delete labelGen.relations;

          this.createLabelGen(labelGen)
          .then( response => {
            console.log(response);
            this.addSuccessMessage("LabelGen was created successfully");
            this.goBack();
          }).catch( () => {
            this.addErrorMessage("Error creating LabelGen, please try again later");
          })
        }
      },
      goBack() {
        this.$router.go(-1);
      },
      setInstance() {
        if (this.id) {
          this.labelGen.id = this.id;
          getLabelGen(this.id)
            .then(response => {
              let instance = response.data;
              for (let property in instance) {
                if (Object.prototype.hasOwnProperty.call(instance, property) && Object.prototype.hasOwnProperty.call(this.labelGen, property)) {
                  this.labelGen[property] = instance[property];
                }
              }
              this.loaded = true;
            })
        } else {
          this.loaded = true;
        }
        this.setDependencies();
      },
      setDependencies() {
        
      }
    },
    components: {
      
    }
  }
</script>
